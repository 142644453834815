html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

html,
body,
#map {
    width: 100vw;
    height: 100%;
}